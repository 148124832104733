<template>
  <div>
    <div class="row" v-if="loading && basket.length <= 0">
      <div class="col-md-8">
        <div class="main-container total-block position-relative" >
          <Loadblock width="150px" height="15px" />
          <div class="row">
            <div class="col-6">
              <div class="mt-2"><Loadblock width="150px" height="30px" /></div>
            </div>
            <div class="col-6">
              <div class="text-right"><Loadblock width="100px" height="15px" /></div>
            </div>
          </div>
          <div class="mt-3">
            <div class="row justify-content-center align-items-center">
                <div class="col-auto">
                  <Loadblock width="25px" height="25px" />
                </div>
                <div class="col">
                  <Loadblock width="100%" height="200px" color="#EFEFEF">
                    <div class="row justify-content-start">
                      <div class="col-4">
                        <div style="padding-top: 20px; padding-left: 20px;">
                          <Loadblock width="160px" height="160px" />
                        </div>
                      </div>
                    </div>
                  </Loadblock>
                </div>
            </div>
          </div>
          <div class="mt-3">
            <div class="row justify-content-center align-items-center">
                <div class="col-auto">
                  <Loadblock width="25px" height="25px" />
                </div>
                <div class="col">
                  <Loadblock width="100%" height="200px" color="#EFEFEF">
                    <div class="row justify-content-start">
                      <div class="col-4">
                        <div style="padding-top: 20px; padding-left: 20px;">
                          <Loadblock width="160px" height="160px" />
                        </div>
                      </div>
                    </div>
                  </Loadblock>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="main-container total-block">
          <div class="total-products">
           <Loadblock width="120px" height="15px" />
          </div>
          <div class="total-scores">
            <Loadblock width="200px" height="15px" />
          </div>
          <div>
              <div class="mt-5 pt-2"><Loadblock width="100%" height="60px" /></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-md-8">
        <div class="main-container total-block position-relative" 
          :class="{'items-loading': loading}">
          <router-link to="/sale/add/" class="sale-link">
            <div
              class="
                basket-small
                d-flex
                justify-content-center
                align-items-center
                mb-3
              "
            >
              <span class="material-icons-outlined">keyboard_backspace</span>
              <div class="basket-small__total-items">Заработать баллы</div>
            </div>
          </router-link>
          <div class="row">
            <div class="col-6">
              <h1 class="heading">Корзина</h1>
            </div>
            <div class="col-6">
              <a v-if="basketTotal.totalItem > 0"
                href="javascript:void(0);"
                class="basket-clear d-block"
                @click="showModalDelete = true"
              >
                <div class="d-flex justify-content-end align-items-end">
                  <div class="basket-small__total-items">Удалить всё</div>
                </div>
              </a>
            </div>
          </div>

          <template v-if="basket.length > 0">
            <div
              v-for="product in basket"
              :key="product.id"
              class="product-item"
              :class="{'unsuccessfully-product': product.errors.length > 0, 'product-load': product.load}"
            >
                <div class="remove-item">
                  <span
                    @click="showDeleteItemModal(product)"
                    class="material-icons-outlined delete-icon"
                    >delete</span
                  >
                </div>
                <div class="product-check">
                  <input
                    v-model="product.status" 
                    true-value="Y"
                    false-value="N"            
                    @change="updateItem(product)"
                    type="checkbox"
                    :disabled="product.load || loading"
                    class="form-check-input product-checkbox"
                  />
                </div>
                <div class="row">
                  <div class="col-auto">
                    <div class="product-code">
                      Код: {{ product.digital_code }}
                    </div>
                  </div>
                  <div v-if="product.errors.length > 0" class="col-auto">
                    <div v-for="(errorMessage, index) in product.errors" :key="index"
                        class="unsuccessfully-product-user d-flex justify-content-center align-items-center">
                      <span class="material-icons mr-2">info</span>
                      <span>{{errorMessage}}</span>
                    </div>
                  </div>
                </div>
                <div  v-if="Object.keys(product.result).length > 0">
                  <div class="product-type">
                    Вид продукции: {{ product.result.product_type }}
                  </div>
                  <div class="product-category">
                    Категория: {{ product.result.category }}
                  </div>
                  <div class="product-mark">
                    Марка: {{ product.result.mark_select }}
                  </div>
                  <div class="product-article">
                    Артикул: {{ product.result.article }}
                  </div>
                  <div class="product-score">
                    Будет начислено баллов: {{ product.result.points }}
                    <span class="material-icons star-icon-small">star</span>
                  </div>
                </div>
            </div>
          </template>
          <template class="col-md-8" v-else>
            <div class="empty">Корзина пустая</div>
          </template>
        </div>
      </div>
      <div class="col-md-4">
        <div class="main-container total-block" v-if="user.program_real == 'N'">
          <h5>Информация по корзине</h5><br>
          <div class="total-products">
            Товаров в корзине:
            {{ basketTotal.totalItem ? basketTotal.totalItem : 0 }} шт
          </div>
          <div class="total-scores">
            Будет начислено баллов:
            {{ basketTotal.totalSum !== undefined ? basketTotal.totalSum : 0 }}
            <span class="material-icons star-icon-small">star</span>
          </div>
          <div>
           <button class="btn basket-total-sale-add mt-4" 
              @click="saleRegistration()"
              :disabled="!isOrderButtonActive">
              <div class="d-flex justify-content-center align-items-center">
                <span class="mr-2">Зафиксировать продажу</span>
                <span class="material-icons-outlined">east</span>
              </div>
            </button>
          </div>
        </div>
        <div class="main-container total-block" v-if="available !== undefined">
          <h5>Информация по ТТ</h5><br>
          <div class="total-products">
            Доступных товаров: {{ available.position !== undefined ? available.position : 0 }} шт
          </div>
          <div class="total-scores">
            Доступных баллов: {{ available.points !== undefined ? available.points : 0 }}
            <span class="material-icons star-icon-small">star</span>
          </div>
          <button class="btn basket-total-link-sale-add mt-4"
            @click="$router.push({ name: 'SaleAdd' })">
            <div class="d-flex justify-content-center align-items-center">
              <span class="material-icons-outlined">keyboard_backspace</span>
              <div class="basket-small__total-items">Заработать баллы</div>
            </div>
          </button>
        </div>
      </div>
    </div>
    <transition name="modal">
      <modal v-if="showModalOrder" @close="showModalOrder = false">
        <modal-header>Обратите внимание</modal-header>

        <modal-body-center>
          <div>
            <div>
              <font-awesome-icon
                icon="info-circle"
                class="icon alt warring-icon"
              />
            </div>
            <div>
              <div class="successfully">Будет зафиксировано как продажа: {{iSuccess}}</div>
              <div class="unsuccessfully">
                Не будет зафиксировано как продажа: {{iError}}
              </div>
              <div class="unsuccessfully-text">
                Продажа может не зафиксироваться, если это уже было сделано
                ранее
              </div>
            </div>
          </div>
        </modal-body-center>

        <modal-footer-center>
          <modal-secondary-button @click="showModalOrder = false"
            >Назад</modal-secondary-button
          >
          <modal-main-button @click="saleRegistration(true)"
            >Зафиксировать
            <font-awesome-icon
              icon="long-arrow-alt-right"
              class="ml-1 icon alt"
            />
          </modal-main-button>
        </modal-footer-center>
      </modal>
    </transition>
    <transition name="modal">
      <modal v-if="showModalDelete" @close="showModalDelete = false">
        <modal-header>Очистить корзину</modal-header>

        <modal-body-center>
          <div>
            <div>
              <font-awesome-icon
                icon="info-circle"
                class="icon alt info-icon"
              />
            </div>
            <div>
              <div class="unsuccessfully-text">
                Вы точно хотите удалить все товары из корзины?
              </div>
            </div>
          </div>
        </modal-body-center>

        <modal-footer-center>
          <modal-secondary-button @click="showModalDelete = false"
            >Отмена</modal-secondary-button
          >
          <modal-main-button @click="deleteAll()"
            >Да, удалить
          </modal-main-button>
        </modal-footer-center>
      </modal>
    </transition>
    <transition name="modal">
      <modal v-if="showModalDeleteItem" @close="showModalDeleteItem = false">
        <modal-header>Очистить корзину</modal-header>

        <modal-body-center>
          <div>
            <div>
              <font-awesome-icon
                icon="info-circle"
                class="icon alt info-icon"
              />
            </div>
            <div>
              <div class="unsuccessfully-text">
                Вы точно хотите удалить этот товар из корзины?
              </div>
            </div>
          </div>
        </modal-body-center>

        <modal-footer-center>
          <modal-secondary-button @click="showModalDeleteItem = false"
            >Отмена</modal-secondary-button
          >
          <modal-main-button @click="deleteItemModel()"
            >Да, удалить
          </modal-main-button>
        </modal-footer-center>
      </modal>
    </transition>
    <transition name="modal">
      <modal v-if="showModalSuccess" @close="showModalSuccess = false">
        <modal-header>Информация отправлена</modal-header>

        <modal-body-center>
          <div>
            <div>
              <font-awesome-icon
                icon="info-circle"
                class="icon alt info-icon"
              />
            </div>
            <div>
              <div class="successfully-text">
                Следите за статусом продажи в истории продаж
              </div>
            </div>
          </div>
        </modal-body-center>

        <modal-footer-center>
          <modal-secondary-button @click="showModalSuccess = false"
            >Назад</modal-secondary-button
          >
          <modal-main-button  @click="$router.push('/sale/history')"
            >
            <div
              class="
                d-flex
                justify-content-center
                align-items-center
              "
            >
              <div class="basket-small__total-items">История продаж</div>
              <span class="material-icons-outlined">east</span>
            </div>
          </modal-main-button>
        </modal-footer-center>
      </modal>
    </transition>
    <transition name="modal">
      <modal v-if="showModalError" @close="showModalError = false">
        <modal-header>Ошибка!</modal-header>

        <modal-body-center>
          <div>
            <div>
              <font-awesome-icon
                icon="info-circle"
                class="icon alt warring-icon"
              />
            </div>
            <div>
              <div v-if="textModalError.length > 0" class="unsuccessfully">
                {{textModalError}}
              </div>
              <div v-else class="unsuccessfully">
                Произошла ошибка на сервере, попробуйте позже!
              </div>
            </div>
          </div>
        </modal-body-center>

        <modal-footer-center>
          <modal-main-button @click="showModalError = false">Назад</modal-main-button>
        </modal-footer-center>
      </modal>
    </transition>
  </div>
</template>

<script>
export default {
  name: "SaleBasket",
  data() {
    return {
      showModalOrder: false,
      showModalDelete: false,
      showModalDeleteItem: false,
      showModalSuccess: false,
      showModalError: false,
      textModalError: '',
      iSuccess: 0,
      iError: 0,
      loadOrder: false,
      itemProductDeleted: {},
    };
  },
  methods: {
    saleRegistrationResponse (response) {
        if (response.data.result !== undefined)
        {
          this.showModalSuccess = true;
        }
        else
        {
          if (response.data.error !== undefined)
          {
            this.textModalError = response.data.error;
          }
          this.showModalError = true;
          this.loadOrder = true;
        }
    },
    async saleRegistration(doItAnyway = false)  { 
      this.loadOrder = true;
      this.textModalError = '';
      
      console.log('dispatch','saleRegistration');
      
      if (doItAnyway)
      {
        this.showModalOrder = false;
        
        await this.$store.dispatch("saleRegistration").then(this.saleRegistrationResponse);
      }
      else
      { 
        await this.$store.dispatch("getBasketData").then(async () => {
          
          for (let i in this.basket)
          {
            let item = this.basket[i];

            if (item.status === 'N')
            {
              this.iError++;
            }
            else
            {
              this.iSuccess++;
            }
          }

          if (this.iError > 0)
          {
            this.showModalOrder = true;
          }
          else
          {
            await this.$store.dispatch("saleRegistration").then(this.saleRegistrationResponse);
          }

        }); 
      }
      this.loadOrder = false;
    },
    deleteItem(product) {
      product.load = true;
      this.$store.dispatch("deleteBasketItem", product.id).finally(()=>{
        product.load = false;
      });
    },
    showDeleteItemModal(product) {
      this.showModalDeleteItem = true
      this.itemProductDeleted = product
    },
    deleteItemModel() {
      this.showModalDeleteItem = false
      this.deleteItem(this.itemProductDeleted)
    },
    updateItem(product) {
      product.load = true;
      this.$store.dispatch("updateBasketItem", product.id).finally(()=>{
        product.load = false;
      });
    },
    deleteAll() {
      this.showModalDelete = false;
      if (!this.loadOrder)
      {
        this.$store.dispatch("deleteBasketAll");
      }
    },
  },
  computed: {
    submitIsActive() {
      return this.basket.length > 0;
    },
    basket() {
      return this.$store.getters.getBasketData;
    },
    basketTotal() {
      return this.$store.getters.gendBasketTotal;
    },
    available() {
      return this.$store.getters.getAvailable;
    },
    loading() {
      return this.$store.getters.getLoading;
    },
    isOrderButtonActive() {

      var isActiveElement =  this.basket.filter((element) => { return element.status === "Y" } ).length > 0;

      var isLoadElement =  this.basket.filter((element) => { return element.load } ).length > 0;
      
      return isActiveElement && !isLoadElement && !this.loading && !this.loadOrder;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
};
</script>

<style scoped>
.main-container {
  min-height: 250px;
}
.empty {
  font-weight: bold;
  font-size: 24px;
  color: #a2a3a5;
  display: flex;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
}
.unsuccessfully-product {
  border: 2px solid rgb(227 0 0 / 30%);
  box-shadow: 0px 8px 24px rgb(255 0 0 / 10%), 0px 0px 48px rgb(255 0 0 / 5%) !important;
}
.unsuccessfully-product-date {
  margin-top: 15px;
  font-weight: bold;
  color: #df0000;
}
.unsuccessfully-product-user {
  font-weight: bold;
  color: #df0000;
}
.successfully {
  font-weight: bold;
}
.unsuccessfully {
  font-weight: bold;
}
.unsuccessfully-text {
  margin-top: 15px;
}
.total-products {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 16px;
}
.total-scores {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}

.product-item {
  box-shadow: 0px 8px 24px rgb(0 0 0 / 8%), 0px 0px 48px rgb(0 0 0 / 4%);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
  position: relative;
  margin-left: 40px;
}

.product-load::after,
.items-loading .product-item::after{
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgb(255 255 255 / 50%);
  border-radius: 10px;
}

.product-check {
  width: 40px;
  height: 40px;
  margin-left: -40px;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.product-check .product-checkbox {
  width: 25px;
  height: 25px;
}
.remove-item {
  position: absolute;
  right: 20px;
  top: 20px;
}
.product-code,
.product-type,
.product-category,
.product-mark,
.product-article,
.product-score {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.product-code {
  font-weight: bold;
}
.product-score {
  font-weight: bold;
}

.star-icon-small {
  color: #ffc107;
  font-size: 18px;
  margin-left: 5px;
}

.warring-icon {
  color: #faa542;
  font-size: 30px;
}

.info-icon {
  color: #3663f2;
  font-size: 30px;
}
.delete-icon {
  color: #bdbdbd;
  cursor: pointer;
}
.delete-icon:hover {
  color: #828282;
  cursor: pointer;
}

.total-block {
  padding: 25px 25px;
}

.sale-link,
.sale-link:hover {
  color: #71747a;
  text-decoration: none;
  display: inline-block;

  font-size: 14px;
  font-weight: 400;
}

.basket-clear,
.basket-clear:hover {
  color: #71747a;
  text-decoration: none;
}

.basket-total-sale-add
{
    background: #3663F2;
    box-shadow: 0px 8px 24px rgb(54 99 242 / 35%), 0px 0px 48px rgb(54 99 242 / 20%);
    border-radius: 7px;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    position: relative;
    padding: 15px 10px;
    width: 100%;
}


.basket-total-link-sale-add
{
    color: #436ef8;
    border-radius: 7px;
    font-weight: 700;
    font-size: 16px;
    background: #FFFFFF;
    position: relative;
    padding: 15px 10px;
    width: 100%;
    border-color: #436ef8;
}
.basket-total-link-sale-add:hover
{
    background-color: rgb(70 110 249 / 3%);
}

.basket-total-sale-add .material-icons-outlined
{
    transform: translateY(2px);
}
</style>